(function()
{
 	var app = angular.module("app");

 	app.run(['functions', function(functions)
 	{

 	}]);

 	app.controller('OrganizationsController', ['$scope', '$rootScope', '$http', 'functions', '$compile', function($scope, $rootScope, $http, functions, $compile)
	{
		$rootScope.organizations	= [];
		$scope.busy 		 		= false;
		$scope.eof 					= false;
		$scope.offset 				= 0;
		$scope.perpage 				= 10;
		$scope.timeout 				= null;
		$scope.ignore_it 			= false;

		$scope.map_organizations = function()
		{
			return $rootScope.organizations;
		}

		$rootScope.searchTimeout = function()
		{
			// Clear any previous calls to this function.
			clearTimeout($scope.timeout);
			jQuery("#location-q").blur();

			// Cancel any HTTP request currently still in progress.
			if($rootScope.http_worker != null)
			{
				$rootScope.http_worker.resolve();
			}

			// Trigger a search in 1 second.  This gives the user a chance to whack multiple inputs before a search happens.
          	$scope.timeout = setTimeout(function() { $scope.search() }, 1000);
		}

		$scope.search = function()
		{
			// setCookie("filters", JSON.stringify($rootScope.filters), 365);

			// Cancel any HTTP request currently still in progress.
			if($rootScope.http_worker != null)
			{
				$rootScope.http_worker.resolve();
			}

			$rootScope.organizations 	= [];
			$scope.offset 				= 0;
			$scope.eof 					= false;
			$scope.nextPage();
		}

		$scope.nextPage = function()
		{
			if ($scope.busy || $scope.eof)
			{
				return;
			}

			$scope.busy = true;

			functions.loading();

			var data = 	{
							'offset': 			$scope.offset,
							'limit': 			$scope.perpage,
							'keywords': 		$rootScope.keywords,
							'radius': 			($rootScope.radius_index == ($rootScope.distances.length-1) ? 1000000 : $rootScope.radius),
							'latitude': 		$rootScope.latitude,
							'longitude':		$rootScope.longitude,
							'unit':				$rootScope.unit,
							'scale_min':		$rootScope.scale_min,
							'scale_max':		$rootScope.scale_max,
							'transportation':	$rootScope.transportation,
							'pickup':			$rootScope.pickup,
							'filters': 			JSON.stringify($rootScope.filters),
							'ignore_it': 		($scope.ignore_it ? "1" : "0"),
							'location_id':		getCookie("location_id")
						};

			$scope.ignore_it = true;

			$http(
		    {
		    	method: 'POST',
  			    url: '/ajaxdata/get_all/organization',
  			    data: jQuery.param(data),
  			    headers: {'Content-Type': 'application/x-www-form-urlencoded'},
  			    timeout: $rootScope.http_worker
  			}).
			success(function(data, status, headers, config)
			{
				if(data.length < $scope.perpage)
				{
					$scope.eof = true;
				}

				for(var i=0; i<data.length; i++)
				{
					for(var j=0; j<data[i].tags.length; j++)
					{
						if(data[i].tags[j].child_tags == null)
						{
							data[i].tags[j].child_tags = "[]";
						}

						data[i].tags[j].child_tags = JSON.parse(data[i].tags[j].child_tags);
					}

					data[i].onlist = false;

					for(var x=0; x<$rootScope.list.length; x++)
					{
						if(data[i].organization_id == $rootScope.list[x].organization_id)
						{
							data[i].onlist = true;
							break;
						}
					}

					// data[i].distance = "";
					$rootScope.organizations.push(data[i]);
				}

				// Rebind tabs to the new organization vcards
				setTimeout(function() { jQuery.nvTabs(); }, 10);

				// $rootScope.organizations = data;
				$scope.offset += $scope.perpage;
				$scope.busy = false;

				functions.loaded();
			}).
			error(function(data, status, headers, config)
			{
				error("Error Loading Organizations", data, true, null);
				functions.loaded();
				$scope.busy = false;
			});
		};

		$scope.addList 		= function(organization) { app.addList(organization); 		};
		$scope.removeList 	= function(organization) { app.removeList(organization); 	};

		$rootScope.$watch
		(
		 	function(scope)
		 	{
		 		return $rootScope.reload_search;
		 	},
		 	function(newValue, oldValue)
		 	{
		 		if(newValue == true)
		 		{
		 			$rootScope.reload_search = false;
		 			$scope.searchTimeout();
		 		}
		 	}
	 	);

	 	$rootScope.$watch
		(
		 	function(scope)
		 	{
		 		return $rootScope.filters;
		 	},
		 	function(newValue, oldValue)
		 	{
		 		functions.setfilters();
          		setCookie("filters", JSON.stringify($rootScope.filters), 365);

		 		$scope.ignore_it = false;
		 		$scope.searchTimeout();
		 	},
		 	true
	 	);

        $rootScope.$watch
		(
			function(scope)
			{
				return $rootScope.keywords;
			},
          	function(newValue, oldValue)
          	{
          		if(newValue == oldValue) { return; }
          		$scope.searchTimeout();
          	}
        );
	}]);
})();
